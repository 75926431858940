import gql from 'graphql-tag'

const GC_ADD_DOCUMENT = gql`
mutation insertDocument($document: [library_insert_input!]!) {
  insert_library(objects: $document) {
    affected_rows
  }
}
`
const GC_UPDATE_DOCUMENT = gql`
mutation updateDocument($doc_id: Int!, $document: library_set_input!) {
  update_library(where: {id: {_eq: $doc_id}}, _set: $document) {
    affected_rows
  }
}
`

const GC_DOCUMENT_LIST = gql`
query getDocumentList {
    library(order_by: {order: asc_nulls_last}) {
      id
      media
      title
      chapter
      isInfo
      chapter_object {
        id
        number
        color
        title
      }
      title
      media_object {
        description
        name
        path
        title
      }
    }
  }
`

const GC_DELETE_DOCUMENT = gql`
mutation MyMutation($id: Int!) {
  delete_library(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
  GC_ADD_DOCUMENT,
  GC_UPDATE_DOCUMENT,
  GC_DOCUMENT_LIST,
  GC_DELETE_DOCUMENT
}
