import axios from "axios";
import {
  GC_AUTH_TOKEN,
  GC_REFRESH_TOKEN,
  GC_REFRESH_TOKEN_EXPIRES,
  GC_USER_ID
} from "@/constants/jwt";
import {
  GC_USER_INFOS,
  GC_USER_INSCRIPTION,
  GC_CHECK_DATA_BY_CODE,
  GC_UPDATE_USER_INSCRIPTION,
  GC_GET_USER_INSCRIPTION_DATA,
  GC_UPDATE_USER_DATA,
  GC_UPDATE_USER_MOTIV,
  GC_SIGN_UP_TRACKING,
  GC_USER_INSCRIPTION_TRACK,
  CHECK_USER
} from "@/graphql/user";

import router from "@/router/index";
import apollo from "@/apolloClient";
import { findToken } from "@/mixins/findToken";
import {
  CHECK_IMPORTED_USERS,
  SET_IMPORTED_USER_LINKED
} from "../graphql/user";

const api = process.env.VUE_APP_API;
export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    isLoggedIn: !!findToken(),
    error: false
  },
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;
      state.isLoggedIn = true;
    },
    SET_ERROR(state, value) {
      state.error = value;
    }
  },
  getters: {
    user: state => {
      return state.user;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    error(state) {
      return state.error;
    }
  },
  actions: {
    async signIn({ commit, dispatch }, { username, password, remember }) {
      try {
        findToken();
        const {
          data: { access, id, refresh }
        } = await axios.post("https://api.ma-copro.org/auth/authenticate", {
          username,
          password,
          remember
        });
        if (remember) {
          window.localStorage.setItem(GC_REFRESH_TOKEN, refresh.token);
          window.localStorage.setItem(
            GC_REFRESH_TOKEN_EXPIRES,
            refresh.expires
          );
        }
        let parsedToken = parseJwt(access.token);
        window.localStorage.setItem(GC_USER_ID, parsedToken.username);
        window.localStorage.setItem(GC_AUTH_TOKEN, id.token);
        await dispatch("getCurrentUserInfo", true);

        commit("SET_ERROR", false);
      } catch (e) {
        commit("SET_ERROR", true);
      }
    },
    async getCurrentUserInfo({ commit, dispatch, state }, isConnection) {
      const currentUserId = localStorage.getItem(GC_USER_ID);

      if (state.isLoggedIn || isConnection) {
        try {
          const response = await apollo.query({
            query: GC_USER_INFOS,
            variables: {
              id: currentUserId
            }
          });
          if (!response.data.users || !response.data.users.length)
            await dispatch("logout");
          else {
            // if (!response.data.users[0].name) await dispatch('migrateUserData')
            commit("SET_USER", response.data.users[0]);

            // Send log data
            await dispatch(
              "Logs/insertLogBySlug",
              {
                typeSlug: "login"
              },
              {
                root: true
              }
            );
          }
        } catch (err) {
          await dispatch("logout");
        }
      } else {
        await dispatch("logout");
      }
    },
    logout({ state }) {
      localStorage.removeItem(GC_AUTH_TOKEN);
      // sessionStorage.removeItem(GC_AUTH_TOKEN)
      // localStorage.removeItem(GC_USER_ID)
      localStorage.removeItem(GC_REFRESH_TOKEN);
      localStorage.removeItem(GC_REFRESH_TOKEN_EXPIRES);
      state.user = null;
      state.isLoggedIn = false;
      router
        .push({
          name: "auth"
        })
        .catch(err => err);
    },
    async inscription({ dispatch }, user) {
      const userObj = { ...user };
      delete userObj.confirm;
      const password = userObj.password;
      delete userObj.password;
      try {
        const cog = await axios.post(
          `${process.env.VUE_APP_API}auth/register`,
          {
            email: userObj.email,
            password
          }
        );
        // find a match in imported users
        const check = await apollo.query({
          query: CHECK_IMPORTED_USERS,
          variables: {
            mail: userObj.email
          }
        });
        userObj.residence_id = check.data.imported_users_with_mail.length
          ? check.data.imported_users_with_mail[0].acquisition_id
          : null;
        if (userObj.residence_id) {
          await apollo.mutate({
            mutation: SET_IMPORTED_USER_LINKED,
            variables: {
              mail: userObj.email
            }
          });
        }
        userObj.user_id = cog.data.message;
        const response = await apollo.mutate({
          mutation: GC_USER_INSCRIPTION,
          variables: {
            user: userObj
          }
        });

        return response;
      } catch (error) {
        if (
          error.response.data.message ===
          "PreSignUp failed with error Email address already registered"
        ) {
          throw { code: 1, message: "Adresse email déjà utilisée." };
        } else {
          throw {
            code: 2,
            message: "Une erreur est survenue, veuillez réessayer.",
            error
          };
        }
      }
    },
    async newSignUpTrack(ctx, data) {
      console.log(data);
      try {
        const response_track = await apollo.mutate({
          mutation: GC_USER_INSCRIPTION_TRACK,
          variables: {
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            email: data.user.email,
            type: data.type
          }
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateMotiv({ ctx }, user) {
      console.log(user);
      const config = {
        headers: {
          "x-hasura-email": user.email,
          "x-hasura-code": null
        }
      };
      try {
        const response = await axios.post(
          process.env.VUE_APP_BACKEND_URL,
          {
            query: GC_UPDATE_USER_MOTIV,
            variables: {
              id: user.id,
              reason: user.reason
            }
          },
          config
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async refreshToken({ dispatch }) {
      const refreshToken = localStorage.getItem(GC_REFRESH_TOKEN);
      const refreshTokenExpires = localStorage.getItem(
        GC_REFRESH_TOKEN_EXPIRES
      );
      if (!refreshToken || new Date(refreshTokenExpires) < new Date()) {
        return dispatch("logout");
      } else {
        try {
          const {
            data: { access, id }
          } = await axios.post(api + "auth/authenticate", {
            token: localStorage.getItem(GC_REFRESH_TOKEN)
          });
          window.localStorage.setItem(GC_AUTH_TOKEN, id.token);
          // return res
        } catch (error) {
          console.log("error:", error);
        }
      }
    },
    async checkDataByCode({ dispatch }, code) {
      console.log("checkDataByCode", code);
      const config = {
        headers: {
          "x-hasura-code": code
        }
      };
      const {
        data: {
          data: { user_inscription }
        }
      } = await axios.post(
        process.env.VUE_APP_BACKEND_URL,
        {
          query: GC_CHECK_DATA_BY_CODE
        },
        config
      );
      dispatch("newSignUpTrack", {
        user: user_inscription[0],
        type: 2
      });

      return user_inscription[0];
    },
    async updateUserInscription(
      { dispatch },
      { user, code, email, user_id, canSendData }
    ) {
      try {
        console.log("updateUser...", code);
        const config = {
          headers: {
            "x-hasura-code": code,
            "x-hasura-email": null
          }
        };
        const res = await axios.post(
          process.env.VUE_APP_BACKEND_URL,
          {
            query: GC_UPDATE_USER_INSCRIPTION,
            variables: {
              code,
              email,
              user_id,
              canSendData
            }
          },
          config
        );
        await dispatch("newSignUpTrack", {
          user,
          type: 3
        });
        return res;
      } catch (e) {
        console.log(e);
        return e;
      }
    },
    async migrateUserData({ dispatch, commit }) {
      const parsedToken = parseJwt(localStorage.getItem(GC_AUTH_TOKEN));
      const {
        data: { user_inscription }
      } = await apollo.query({
        query: GC_GET_USER_INSCRIPTION_DATA,
        variables: {
          email: parsedToken.email
        }
      });
      if (user_inscription.length) {
        const {
          id,
          firstname,
          lastname,
          email,
          status,
          adress,
          structure,
          city,
          cedex,
          nb_lots,
          residence_id,
          canSendData
        } = user_inscription[0];
        let user = {
          name: lastname,
          first_name: firstname,
          email,
          onv_status: status,
          adress,
          structure,
          city,
          cedex,
          nb_lots,
          residence_id,
          canSendData
        };
        const res = await apollo.mutate({
          mutation: GC_UPDATE_USER_DATA,
          variables: {
            user_id: parsedToken["cognito:username"],
            data_id: id,
            user
          }
        });

        commit("SET_USER", res.data.update_users.returning[0]);
      }
    }
  }
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
