import gql from 'graphql-tag'

export const GET_GAMES_BY_ID = gql`
query MyQuery($id: uuid!) {
  game(where: {id: {_eq: $id}}) {
    id
    instruction
    title
    name
    updated_at
    gameType {
      name
      slug
    }
  }
}
`
export const GET_QUESTIONS_BY_ID = gql`
query MyQuery($id: uuid!) {
  question(where: {game_id: {_eq: $id}}) {
    text
    correction
    answers {
      is_correct
      text
    }
  }
}`
