import apollo from '@/apolloClient'
import { GC_CHAPTER_LIST, GC_CHAPTER_BY_ID, GC_ADD_PLANCHE_ZONE_VISITED, GC_GET_CHAPTER_SCORE_BY_ID } from '@/graphql/chapter'

export default {
  namespaced: true,
  state: {
    chapters: [],
    currentChapter: {},
    currentChapterLogData: null
  },
  mutations: {
    SET_CHAPTER_LIST(state, chapters) {
      state.chapters = chapters
    },
    SET_CURRENT_CHAPTER(state, { chapter, logData }) {
      if (!state.currentChapterLogData || state.currentChapterLogData.data_key !== chapter.id) {
        state.currentChapterLogData = logData
      }

      state.currentChapter = chapter
    }
  },
  getters: {
    chapters: (state) => {
      return state.chapters
    },
    currentChapter: (state) => {
      return state.currentChapter
    },
    currentChapterLogData: (state) => {
      return state.currentChapterLogData
    }
  },
  actions: {
    async getChapterList({ commit }) {
      const response = await apollo.query({
        query: GC_CHAPTER_LIST
      })
      response.data.chapter.map(chapter => {
        if (chapter.planche_1) chapter.planche_1.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_1.path
        if (chapter.planche_2) chapter.planche_2.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_2.path
      })
      commit('SET_CHAPTER_LIST', response.data.chapter)
      return response.data.chapter
    },
    async getChapterById({ state, commit, rootState, dispatch }, id) {
      const user_id = rootState.Auth.user.id
      
      const { data: { chapter, planche_zone_visited, planche_zone_visited_2 } } = await apollo.query({
        query: GC_CHAPTER_BY_ID,
        variables: {
          id,
          user_id
        }
      })

      if (chapter.planche_1) chapter.planche_1.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_1.path
      if (chapter.planche_2) chapter.planche_2.url = process.env.VUE_APP_AWS_S3_URL + '/' + chapter.planche_2.path
      if (planche_zone_visited_2.length) chapter.lastZone = planche_zone_visited_2[0]
      else if (planche_zone_visited.length) chapter.lastZone = planche_zone_visited[0]

      // Create log data if needed
      let logData = state.currentChapterLogData

      if (!logData || logData.dataKey !== id) {
        logData = { dataKey: id, typeSlug: 'scenario' }

        try {
          const result = await dispatch('Logs/insertLogBySlug', logData, { root: true })

          // Get log id
          logData.id = result.id
        } catch (err) {
          logData = null
        }
      }

      commit('SET_CURRENT_CHAPTER', { chapter, logData })

      return chapter
    },
    async addPlancheZoneVisited({ commit }, { planche_zone_id, user_id }) {
      const { data } = await apollo.mutate({
        mutation: GC_ADD_PLANCHE_ZONE_VISITED,
        variables: {
          planche_zone_id,
          user_id
        }
      })
      return data
    },
    async getChapterScoreById({ commit, rootState }, chapter_id) {
      let user_id = rootState.Auth.user.id
      const { data: { chapter_by_pk } } = await apollo.query({
        query: GC_GET_CHAPTER_SCORE_BY_ID,
        variables: {
          user_id,
          chapter_id
        }
      })
      const { planche_1, planche_2 } = chapter_by_pk

      const chapterScore = calculateChapterScore(planche_1, planche_2)
      // const dataP2 = calculatePlancheScore(planche_2)
      chapter_by_pk.score = chapterScore.score
      if (chapterScore.isFinished) chapter_by_pk.isFinished = true
      chapter_by_pk.completion = Math.round(chapterScore.completion)
      return chapter_by_pk
    }
  }
}

function calculateChapterScore(planche_1, planche_2) {
  let nbGames = 0
  let totalScore = 0
  let nbZones = 0
  let visitedZones = 0
  const zones = [...planche_1.planche_zones, ...planche_2.planche_zones]
  zones.forEach(zone => {
    nbZones++
    if (zone.planche_zone_visiteds.length) visitedZones++
    if (zone.zone_type === 'game') {
      zone.planche_zone_media.forEach(media => {
        nbGames++
        if (media.game.score_logs.length) {
          totalScore += media.game.score_logs[0].data.value
        }
      })
    }
  })
  const score = totalScore ? totalScore * 5 / nbGames : 0
  var roundedScore = score.toFixed(2);
  var rounded = Number(roundedScore);
  return { isFinished: nbZones === visitedZones, completion: visitedZones ? (visitedZones / nbZones) * 100 : 0, score: rounded }
}
