import gql from "graphql-tag";

const GC_USER_INFOS = gql`
  query getCurrentUser($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      name
      first_name
      email
      onv_status
      adress
      structure
      city
      cedex
      nb_lots
      residence_id
    }
    # lastPage: logs(where: {logType: {slug: {_eq: "last_visited_page"}}, user_id: {_eq: $id}}, order_by: {updated_at: asc}) {
    # 	id
    #   data
    # }
  }
`;
const GC_USER_INSCRIPTION = gql`
  mutation insertUserInscription($user: [user_inscription_insert_input!]!) {
    insert_user_inscription(objects: $user) {
      affected_rows
      returning {
        id
        email
        firstname
        lastname
      }
    }
  }
`;
const GC_CHECK_MAIL = gql`
  query checkMail($mail: String!) {
    users(where: { email: { eq: $mail } }) {
      id
      name
      first_name
    }
    user_inscription(where: { email: { _eq: $mail } }) {
      id
    }
  }
`;

const GC_CHECK_DATA_BY_CODE = `
query checkDataByCode {
  user_inscription(where: {user_id: {_is_null: true}}) {
    email
    firstname
    lastname
    id
  }
}

`;
const GC_GET_USER_INSCRIPTION_DATA = gql`
  query getUserInscriptionData($email: String!) {
    user_inscription(where: { email: { _eq: $email } }) {
      id
      firstname
      lastname
      email
      status
      adress
      structure
      city
      cedex
      nb_lots
      residence_id
      canSendData
    }
  }
`;
const GC_UPDATE_USER_INSCRIPTION = `
mutation updateUserInscription($email: String!, $code: String!, $user_id: uuid!, $canSendData: Boolean!) {
  update_user_inscription(where: {code: {_eq: $code}}, _set: {email: $email, user_id: $user_id, canSendData: $canSendData}) {
    affected_rows
  }
}
`;
const GC_UPDATE_USER_MOTIV = `
mutation MyMutation($reason: String!, $id: uuid!) {
  update_user_inscription_by_pk(pk_columns: {id: $id}, _set: {reason: $reason}) {
    user_id
  }
}`;
const GC_SIGN_UP_TRACKING = `
mutation MyMutation($code: String!, $date: date!) {
  update_user_inscription(where: {code: {_eq: $code}}, _set: {hasEnteredCode: true, dateHasEnteredCode: $date}) {
    affected_rows
  }
}
`;
const GC_UPDATE_USER_DATA = gql`
  mutation updateUserInscription(
    $user: users_set_input!
    $user_id: uuid!
    $data_id: uuid!
  ) {
    update_users(where: { id: { _eq: $user_id } }, _set: $user) {
      affected_rows
      returning {
        id
        name
        first_name
        email
        onv_status
        adress
        structure
        city
        cedex
        nb_lots
        residence_id
      }
    }
    delete_user_inscription(where: { id: { _eq: $data_id } }) {
      affected_rows
    }
  }
`;
const GC_USER_INSCRIPTION_TRACK = gql`
  mutation MyMutation(
    $type: Int!
    $firstname: String!
    $lastname: String!
    $email: String!
  ) {
    insert_user_inscription_tracks(
      objects: {
        type: $type
        firstname: $firstname
        lastname: $lastname
        email: $email
      }
    ) {
      affected_rows
    }
  }
`;

const CHECK_IMPORTED_USERS = gql`
  query checkImportedUsers($mail: String!) {
    imported_users_with_mail(where: { mail: { _eq: $mail } }) {
      acquisition_id
    }
  }
`;
const SET_IMPORTED_USER_LINKED = gql`
  mutation setImportedUserLinked($mail: String!) {
    update_imported_users_with_mail(
      where: { mail: { _eq: $mail } }
      _set: { is_linked: true }
    ) {
      affected_rows
    }
  }
`;
export {
  GC_USER_INFOS,
  GC_USER_INSCRIPTION,
  GC_CHECK_DATA_BY_CODE,
  GC_UPDATE_USER_INSCRIPTION,
  GC_GET_USER_INSCRIPTION_DATA,
  GC_UPDATE_USER_DATA,
  GC_CHECK_MAIL,
  GC_UPDATE_USER_MOTIV,
  GC_SIGN_UP_TRACKING,
  GC_USER_INSCRIPTION_TRACK,
  CHECK_IMPORTED_USERS,
  SET_IMPORTED_USER_LINKED
};
