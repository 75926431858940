import { GC_ADD_LOG_ONE, GC_UPDATE_LOG_ONE, GC_GET_LOG_TYPE_GAME, GC_GET_LOG_TYPE_LIST } from '@/graphql/logs'
import apollo from '@/apolloClient'
export default {
  namespaced: true,
  state: {
    typeList: [],
    typeBySlug: {}
  },
  mutations: {
    SET_LOG_TYPE_LIST(state, types) {
      state.typeList = types
      state.typeBySlug = types.reduce((dict, type) => {
        dict[type.slug] = type
        return dict
      }, {})
    }
  },
  actions: {
    async getTypeList({ commit }) {
      const response = await apollo.query({ query: GC_GET_LOG_TYPE_LIST })

      commit('SET_LOG_TYPE_LIST', response.data.log_type)
    },
    async insertLog ({ commit }, {data, user_id, data_key, log_type_id}) {
      const result = await apollo.query({
        query: GC_ADD_LOG_ONE,
        variables: { data, user_id, data_key, log_type_id }
      })
    },
    async insertLogBySlug({ state, dispatch, rootState }, { id = null, dataKey = null, data = {}, typeSlug }) {
      // Load log types if needed
      if (!state.typeList || state.typeList.length <= 0) {
        await dispatch('getTypeList')

        if (state.typeList.length <= 0)
          return// todo
      }

      // Find log type id
      const logType = state.typeBySlug[typeSlug]

      if (!logType)
        return

      // Get user id
      const userId = rootState.Auth.user?.id

      if (!userId)
        return

      if (id) {
        // Update the log
        const response = await apollo.mutate({
          mutation: GC_UPDATE_LOG_ONE,
          variables: {
            id,
            data_key: dataKey,
            data,
            log_type_id: logType.id,
            user_id: userId
          }
        })

        return response.data.update_logs_by_pk
      } else {
        // Add the log
        const response = await apollo.mutate({
          mutation: GC_ADD_LOG_ONE,
          variables: {
            data_key: dataKey,
            data,
            log_type_id: logType.id,
            user_id: userId
          }
        })

        return response.data.insert_logs_one
      }
    },
    async getLogTypeGame ({ commit }) {
      const result = await apollo.query({
        query: GC_GET_LOG_TYPE_GAME
      })
      return result.data.log_type[0]
    }
  }
}
