import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify(
    {theme: {
        themes: {
          light: {
            primary: '#0f5b91',
            error:'#de6359'
          },
        },
      },}
)
