import { GC_ADD_DOCUMENT, GC_UPDATE_DOCUMENT, GC_DOCUMENT_LIST, GC_DELETE_DOCUMENT } from '@/graphql/library'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      async saveDocument(ctx, document) {

        delete document.link_to_chapter
        if(document.id) {
          const { id } = document
          delete document.id
          let response = await apollo.mutate({
            mutation: GC_UPDATE_DOCUMENT,
            variables: {
              document:document,
              doc_id:id
            }
          })
        } else {

          let response = await apollo.mutate({
            mutation: GC_ADD_DOCUMENT,
            variables: {
              document
            }
          })

        }
      },
      async deleteDocument(ctx, id) {
        let response = await apollo.mutate({
          mutation: GC_DELETE_DOCUMENT,
          variables:{
            id:id
          }
        })
      },
      async getDocumentsList(ctx) {
        let response = await apollo.query({
          query: GC_DOCUMENT_LIST
        })

        return response.data.library
      }
    }
}
