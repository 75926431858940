import { ApolloClient } from 'apollo-client'
import router from '@/router/index'
import store from '@/store/index'
import 'isomorphic-unfetch'
import { GC_AUTH_TOKEN, GC_REFRESH_TOKEN } from '@/constants/jwt'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { findToken } from '@/mixins/findToken'

import promiseToObservable from './promiseToObservable.js'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_BACKEND_URL
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = findToken()
  let headers = {}
  if (token) headers.authorization = `Bearer ${token}`
  operation.setContext({
    headers: headers
  })
  return forward(operation)
})

function omitTypename (key, value) {
  return key === '__typename' ? undefined : value
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

const errorLink = onError(err => {
  // eslint-disable-next-line
  if (err.response && err.response.errors ) {
    if (err.response.errors[0].extensions.code === 'invalid-jwt') {
      return promiseToObservable(store.dispatch('Auth/refreshToken')).flatMap(() => err.forward(err.operation));
    } else if (err.response.errors[0].extensions.code === 'access-denied') {
      store.dispatch('Auth/logout')
    }
  }
})

const link = new ApolloLink.from([
  omitTypenameLink,
  errorLink,
  middlewareLink,
  httpLink
])

const cache = new InMemoryCache()

const client = new ApolloClient({
  // uri: 'https://cooc-admins.walkingnerds.dev/hasura/v1/graphql',
  link: link,
  cache
})

client.defaultOptions = {
  query: {
    fetchPolicy: 'no-cache'
  }
}

function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

export default client
