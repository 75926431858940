import gql from 'graphql-tag'

export const GC_ADD_LOG_ONE = gql`
mutation AddLog($data_key: uuid = null, $data: jsonb!, $log_type_id: uuid!, $user_id: uuid!) {
  insert_logs_one(object: {
    data_key: $data_key,
    data: $data,
    user_id: $user_id,
    log_type_id: $log_type_id
  }) {
    id
  }
}
`

export const GC_UPDATE_LOG_ONE = gql`
mutation UpdateLog($id: uuid!, $data_key: uuid = null, $data: jsonb!, $log_type_id: uuid!, $user_id: uuid!) {
  update_logs_by_pk(pk_columns: { id: $id }, _set: {
    data_key: $data_key,
    data: $data,
    user_id: $user_id,
    log_type_id: $log_type_id
  }) {
    id
  }
}
`

export const GC_GET_LOG_TYPE_GAME = gql`
query GetLogTypeGame {
    log_type(where: {slug: {_eq: "game"}}) {
      id
      name
      slug
    }
  }
`

export const GC_GET_LOG_TYPE_LIST = gql`
query GetLogTypeList {
    log_type {
      id
      name
      slug
    }
  }
`
