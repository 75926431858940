import gql from 'graphql-tag'

const GC_CHAPTER_LIST = gql`
query getChapterList {
  chapter(order_by: {number: asc}) {
    id
    number
    title
    color
  }
}
`
const GC_CHAPTER_BY_ID = gql`
query getChapterById($id: uuid!, $user_id: uuid!) {
  chapter: chapter_by_pk(id: $id) {
    id
    number
    title
    color
    planche_1 {
      name
      path
      id
      thumbnail_path
      title
      planche_zones(order_by: {order: asc}) {
        id
        position
        zone_type
        order
        planche_zone_visiteds(where: {user_id: {_eq: $user_id}}) {
          id
        }
        planche_zone_media(order_by: {game: {name: asc}}) {
          id
          game {
            id
            instruction
            title
            name
            updated_at
            gameType {
              name
              slug
            }
            questions: questionId {
              id
              answers {
                is_correct
                text
                id
              }
              correction
              text
            }
          }
          video_comedien {
            id
            name
            path
          }
          video_expert {
            name
            id
            path
            title
          }
          image {
            name
            id
            path
          }
        }
      }
    }
    planche_2 {
      name
      path
      id
      thumbnail_path
      title
      planche_zones(order_by: {order: asc}) {
        id
        position
        zone_type
        order
        planche_zone_visiteds(where: {user_id: {_eq: $user_id}}) {
          id
        }
        planche_zone_media(order_by: {game: {name: asc}}) {
          id
          game {
            id
            instruction
            title
            name
            updated_at
            gameType {
              name
              slug
            }
            questions: questionId {
              id
              answers {
                is_correct
                text
                id
              }
              correction
              text
            }
          }
          video_comedien {
            id
            name
            path
          }
          video_expert {
            name
            id
            path
          }
          image {
            name
            id
            path
          }
        }
      }
    }
  }
  planche_zone_visited_2: planche_zone_visited(where: {_and: [{user_id: {_eq: $user_id}}, {planche_zone: {planche: {chaptersByPlanche2Id: {id: {_eq: $id}}}}}]}, order_by: {created_at: desc}, limit: 1) {
    id
    created_at
    planche_zone {
      id
    }
  }
  planche_zone_visited(where: {_and: [{user_id: {_eq: $user_id}}, {planche_zone: {planche: {chapters: {id: {_eq: $id}}}}}]}, order_by: {created_at: desc}, limit: 1) {
    id
    created_at
    planche_zone {
      id
    }
  }
}
`
const GC_ADD_PLANCHE_ZONE_VISITED = gql`
  mutation addPlancheZoneVisited($planche_zone_id: uuid!, $user_id: uuid!) {
  insert_planche_zone_visited(objects: {user_id: $user_id, planche_zone_id: $planche_zone_id}) {
    affected_rows
  }
}
`
const GC_GET_CHAPTER_SCORE_BY_ID = gql`
  query getChapterScoreById($chapter_id: uuid!, $user_id: uuid!) {
  chapter_by_pk(id: $chapter_id) {
    color
    number
    title
    id
    planche_1 {
      id
      planche_zones {
        id
        zone_type
        planche_zone_visiteds(where: {user_id: {_eq: $user_id}}) {
          id
        }
        planche_zone_media {
          game {
            score_logs(order_by: {created_at: desc}, limit: 1, where: {user_id: {_eq: $user_id}}) {
              id
              data_key
              created_at
              data
            }
          }
        }
      }
    }
    planche_2 {
      id
      planche_zones {
        id
        zone_type
        planche_zone_visiteds(where: {user_id: {_eq: $user_id}}) {
          id
        }
        planche_zone_media {
          game {
            id
            score_logs(order_by: {created_at: desc}, limit: 1, where: {user_id: {_eq: $user_id}}) {
              id
              data_key
              created_at
              data
            }
          }
        }
      }
    }
  }
}
`

export {
  GC_CHAPTER_LIST,
  GC_CHAPTER_BY_ID,
  GC_ADD_PLANCHE_ZONE_VISITED,
  GC_GET_CHAPTER_SCORE_BY_ID
}
