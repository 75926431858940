<template>
  <section class="launchscreen d-flex justify-center align-center" ref="launchscreen">
    <section v-if="!isMobile" class="d-flex justify-center align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div @click="$router.push('auth')" class="logo-container pa-4" v-on="on">
            <img class="onv-logo" src="@/assets/images/onv_logo.png" alt="">
          </div>
        </template>
        <span>Commencer</span>
      </v-tooltip>
    </section>
    <section v-else class="mobile">
      <div class="logo-container-mobile pa-4 mt-8 mx-2">
        <img class="onv-logo" src="@/assets/images/onv_logo.png" alt="">
      </div>
      <transition name="fade">
        <v-btn @click="$router.push('auth')" color="primary" class="play-btn" absolute v-if="showBtn">Jouer</v-btn>
      </transition>
    </section>

    <!-- <img src="@/assets/images/home_bg_desktop.jpg" alt=""> -->
    <!-- <v-btn @click="$router.push('auth')" large min-width="155" color="primary" class="rounded-lg">Jouer</v-btn> -->
    <!-- <div class="logo-footer">
      <a href="https://www.actionlogement.fr/" target='_blank'><img src="@/assets/images/logos/action-logement-blanc.png" alt="Action Logement"></a>
      <a href="https://www.afpols.fr/" target="_blank"><img src="@/assets/images/logos/afpols-blanc.png" alt="AFPOLS"></a>
    </div> -->
    <div class="logos-bg ">
        <div class="logo py-1">
          <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="">
        </div>
        <div class="logo">
          <img src="@/assets/images/logo_action_logement.png" alt="">
        </div>
      </div>
  </section>
</template>

<script>

import { mapGetters } from 'vuex'
// import desktopBG from '@/assets/images/home_bg_desktop.jpg'
// import mobileBG from '@/assets/images/home_bg_mobile.jpg'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['isMobile'])
  },
  data: () => ({
    showBtn: false
  }),
  mounted () {
    setTimeout(() => {
      this.showBtn = true
    }, 2000);
    // background-image: ;
  }
}
</script>

<style lang="scss">

</style>
