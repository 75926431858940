import gql from 'graphql-tag'
export const GET_VIDEO_INTEGREE = gql`
query getVideoIntegree($id: uuid!) {
    video_part(where: {id: {_eq: $id}}) {
      videoMediaId {
        path
        name
        title
        thumbnail_path
      }
    }
  }
`
export const GET_VIDEO_PEDAGO = gql`
query getVideoPedago($id: uuid!) {
    media(where: {id: {_eq: $id}}) {
      thumbnail_path
      title
      path
    }
  }`
export const GET_PLANCHE_ZONES = gql`
query getPlancheZones($id: uuid!) {
  planche_zone(where: {planche_id: {_eq: $id}}) {
    id
    position
    updated_at
    zone_type
    planche_zone_media {
      id
      game {
        id
        instruction
        title
        name
        gameType {
          name
          slug
        }
        questionId {
          answers {
            is_correct
            text
            id
          }
          correction
          text
        }
      }
      video_comedien {
        id
        name
        path
      }
      video_expert {
        name
        id
        path
        title
      }
    }
  }
}
`
export const GET_PLANCHE_DATA = gql`
query getPlancheData($id: uuid!) {
  planche_data: media_by_pk(id: $id) {
    name
    path
    id
    thumbnail_path
    title
    planche_zones {
      id
      position
      zone_type
      planche_zone_media {
      id
      game {
        id
        instruction
        title
        name
        gameType {
          name
          slug
        }
        questions: questionId {
          answers {
            is_correct
            text
            id
          }
          correction
          text
        }
      }
      video_comedien {
        id
        name
        path
      }
      video_expert {
        name
        id
        path
        title
      }
    }
    }
  }
}
`
