import { GET_VIDEO_INTEGREE, GET_VIDEO_PEDAGO, GET_PLANCHE_ZONES, GET_PLANCHE_DATA } from '@/graphql/medias'
import apollo from '@/apolloClient'
export default {
  namespaced: true,
  actions: {
    async getVideoByContentId ({ commit }, id) {
      const result = await apollo.query({
        query: GET_VIDEO_INTEGREE,
        variables: {
          id: id
        }
      })
      return process.env.VUE_APP_AWS_S3_URL + '/' + result.data.video_part[0].videoMediaId.path
    },
    async getVideoPedagoByContentId ({ commit }, id) {
      const result = await apollo.query({
        query: GET_VIDEO_PEDAGO,
        variables: {
          id: id
        }
      })
      return process.env.VUE_APP_AWS_S3_URL + '/' + result.data.media[0].path
    },
    async getPlancheZones ({ commit }, id) {
      const response = await apollo.query({
        query: GET_PLANCHE_ZONES,
        variables: {
          id: id
        }
      })
      const result = response.data.planche_zone.map(zone => {
        if (zone.game) zone.game.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.game.path
        if (zone.image) zone.image.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.image.path
        if (zone.video) zone.video.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.video.path
        return zone
      })
      return result
    },
    async getPlancheData ({ commit }, id) {
      const { data: { planche_data } } = await apollo.query({
        query: GET_PLANCHE_DATA,
        variables: {
          id: id
        }
      })
      planche_data.url = process.env.VUE_APP_AWS_S3_URL + '/' + planche_data.path
      planche_data.planche_zones.map(zone => {
        if (zone.zone_type === 'video_expert' || zone.zone_type === 'video_comedien') zone.planche_zone_media[0][zone.zone_type].url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.planche_zone_media[0][zone.zone_type].path
        return zone
      })
      // let result = response.data.planche_zone.map(zone => {
      //   if(zone.game) zone.game.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.game.path
      //   if(zone.image) zone.image.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.image.path
      //   if(zone.video) zone.video.url = process.env.VUE_APP_AWS_S3_URL + '/' + zone.video.path
      //   return zone
      // })
      return planche_data
    }
  }
}
