<template>
  <v-app>
    <router-view>
        </router-view>
    </v-app>
</template>

<script>
export default {
  async created () {
    this.$store.commit('SET_APP_WIDTH', window.innerWidth)
  },
  computed: {
    chapters () {
      return this.$store.getters['Chapter/chapters']
    }
  },
  data: () => ({
    windowWidth: window.innerWidth
  }),
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      this.$store.commit('SET_APP_WIDTH', window.innerWidth)
    }
  }
}
</script>

<style lang="scss">
</style>
