import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '@/store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Accueil',
      noAuth: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "Auth" */ '../views/auth/Auth.vue'),
    meta: {
      title: 'Authentification',
      noAuth: true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "Auth" */ '../views/Sandbox.vue')
  },
  {
    path: '/chapitre/:id',
    name: 'chapitre',
    component: () => import(/* webpackChunkName: "Chapitre" */ '../views/BD.vue'),
    meta: {
      title: 'Chapitre',
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "Menu" */ '../views/Menu.vue'),
    meta: {
      title: 'Menu',
    }
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import(/* webpackChunkName: "Menu" */ '../views/Intro.vue'),
    meta: {
      title: 'Introduction',
    }
  },
  {
    path: '/cgu',
    name: 'CGU',
    component: () => import(/* webpackChunkName: "CGU" */ '../views/CGU.vue'),
    meta: {
      title: 'CGU',
      noAuth:true,
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy.vue'),
    meta: {
      title: 'Privacy',
      noAuth:true,
    }
  },
  {
    path: '/register/:code',
    name: 'register',
    component: () => import(/* webpackChunkName: "Menu" */ '../views/auth/EmailValidation.vue'),
    meta: {
      title: 'Validation du mail',
      noAuth: true
    }
  },
  {
    path: '/adminregister/:code',
    name: 'adminregister',
    component: () => import(/* webpackChunkName: "Menu" */ '../views/auth/CreatePassword.vue'),
    meta: {
      title: 'Auth admin',
      noAuth: true
    }
  },
  {
    path: '/reset/:code',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "Menu" */ '../views/auth/PasswordReset.vue'),
    meta: {
      title: 'Reset du mot de passe',
      noAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Authentication check
router.beforeEach(async (to, from, next) => {
  const loggedIn = store.getters['Auth/isLoggedIn']
  const needAuth = !to.meta.noAuth
  if(to.name === 'chapitre') {
    document.title = 'Ma copro, ON Vous donne les clés ! — ' + to.meta.title + ' ' + to.params.id
  }
  else {
    document.title = 'Ma copro, ON Vous donne les clés ! — ' + to.meta.title 
  }
  // Check the authentication status of the user
  if ((to.path === '/' || to.path === '/auth') && loggedIn) return next({ name: 'menu' })
  if (!loggedIn) {
    if (needAuth) {
      return next({ name: 'Home' })
    } else {
      return next()
    }
  }

  // Redirect to home if already logged in
  // if (loggedIn) return next({ name: 'menu' })

  // Handle first page load
  let userInfo = store.getters['Auth/user']

  if (!userInfo) {
    await store.dispatch('Auth/getCurrentUserInfo')

    userInfo = store.state.Auth.user

    // Check if we didn't get userInfo (can be null if token has expired)
    if (!userInfo) {
      return next({ name: 'auth' })
    }
  }
  return next()
})

export default router
