import apollo from '@/apolloClient'
import { GET_GAMES_BY_ID, GET_QUESTIONS_BY_ID } from '@/graphql/games'
export default {
  namespaced: true,
  state: {
    token: null,
    user: null
  },
  mutations: {
    SET_USER (state, userData) {
      state.user = userData
    }
  },
  getters: {
    user: (state) => {
      return state.user
    }
  },
  actions: {
    async getById ({ commit }, id) {
      const result = await apollo.query({
        query: GET_GAMES_BY_ID,
        variables: {
          id: id
        }
      })
      const questions = await apollo.query({
        query: GET_QUESTIONS_BY_ID,
        variables: {
          id: id
        }
      })

      const game = result.data.game[0]
      game.questions = questions.data.question
      return game
    }
  }
}
