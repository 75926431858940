import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import '@/assets/styles/App.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(VueAxios, axios)

Sentry.init({
  Vue,
  dsn: 'https://091548e0f2dc48a687f9c6604d51f056@o566450.ingest.sentry.io/5861081',
  integrations: [new Integrations.BrowserTracing()],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  // VueAxios,
  // axios,
  render: h => h(App)
}).$mount('#app')
