import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth'
import Scenarios from './scenarios'
import Games from './games'
import Medias from './medias'
import Logs from './logs'
import Chapter from './chapter'
import Library from './library'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    appWidth: null
  },
  getters: {
    isMobile (state) {
      return state.appWidth <= 500
    },
    appWidth(state) {
      return state.appWidth
    }
  },
  mutations: {
    SET_APP_WIDTH (state, width) {
      state.appWidth = width
    }
  },
  actions: {
  },
  modules: {
    Auth,
    Scenarios,
    Games,
    Medias,
    Logs,
    Chapter,
    Library
  }
})
